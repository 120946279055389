<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="QuayLai()"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            SỬA THÔNG TIN XE
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="px-2 pt-2">
                <DxValidationGroup ref="formValidation">
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextBox
                                label="Biển kiểm soát (*)"
                                labelMode="floating"
                                v-model="KyLenhTuyChon_ThongTinChung.BienKiemSoat"
                                mode="text"
                                :readOnly="true"
                                styling-mode="underlined"
                                validationMessageMode="always"
                            />
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxDateBox
                                label="Hạn phù hiệu tuyến (*)"
                                labelMode="floating"
                                v-model:value="
                                    KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen
                                "
                                ref="KyDenNgay"
                                displayFormat="dd/MM/yyyy"
                                :use-mask-behavior="true"
                                dropDownButtonTemplate="customIcon"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                autocomplete="off"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Hạn phù hiệu không được bỏ trống!"
                                    />
                                    <DxRangeRule
                                        :max="maxDate"
                                        :message="$i18n.t('DefaultString.MaxDate')"
                                    />
                                    <DxRangeRule
                                        :min="$Helper.Common.getEndDay(new Date())"
                                        message="Hạn phù hiệu phải lớn hơn ngày hiện tại!"
                                    />
                                </DxValidator>
                            </DxDateBox>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxDateBox
                                label="Hạn bảo hiểm xe (*)"
                                labelMode="floating"
                                v-model:value="KyLenhTuyChon_ThongTinChung.HanBaoHiemXe"
                                ref="KyDenNgay"
                                displayFormat="dd/MM/yyyy"
                                :use-mask-behavior="true"
                                dropDownButtonTemplate="customIcon"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                autocomplete="off"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Hạn bảo hiểm xe không được bỏ trống!"
                                    />
                                    <DxRangeRule
                                        :max="maxDate"
                                        :message="$i18n.t('DefaultString.MaxDate')"
                                    />
                                    <DxRangeRule
                                        :min="$Helper.Common.getEndDay(new Date())"
                                        message="Hạn bảo hiểm xe phải lớn hơn ngày hiện tại!"
                                    />
                                </DxValidator>
                            </DxDateBox>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxDateBox
                                label="Hạn đăng kiểm xe (*)"
                                labelMode="floating"
                                v-model:value="KyLenhTuyChon_ThongTinChung.HanDangKiemXe"
                                ref="KyDenNgay"
                                displayFormat="dd/MM/yyyy"
                                :use-mask-behavior="true"
                                dropDownButtonTemplate="customIcon"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                autocomplete="off"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Hạn đăng kiểm xe không được bỏ trống!"
                                    />
                                    <DxRangeRule
                                        :max="maxDate"
                                        :message="$i18n.t('DefaultString.MaxDate')"
                                    />
                                    <DxRangeRule
                                        :min="$Helper.Common.getEndDay(new Date())"
                                        message="Hạn đăng kiểm phải lớn hơn ngày hiện tại!"
                                    />
                                </DxValidator>
                            </DxDateBox>
                        </div>
                    </div>
                </DxValidationGroup>

                <div class="button-bottom row justify-space-between align-center">
                    <DxButton
                        text="Hủy"
                        type="danger"
                        styling-mode="text"
                        @click="QuayLai()"
                    />
                    <DxButton
                        text="Lưu"
                        type="default"
                        styling-mode="contained"
                        @click="CapNhatThongTinGiayToXe()"
                    />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import {
    DxTextBox,
    DxButton,
    DxDateBox,
    DxValidator,
    DxValidationGroup,
} from "devextreme-vue";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";

export default {
    components: {
        DxValidationGroup,
        DxDateBox,
        DxTextBox,
        DxButton,
        DxRequiredRule,
        DxRangeRule,
        DxValidator,
        DxDateBox,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
    },
    data() {
        return {
            tmpThongTinChung: {
                HanBaoHiemXe: null,
                HanDangKiemXe: null,
                HanPhuHieuTuyen: null,
            },
        };
    },
    computed: {
        LenhStore() {
            return this.$store.state.LenhTuyChon;
        },
        KyLenhTuyChon_ThongTinChung: {
            get() {
                return this.LenhStore.KyLenhTuyChon_ThongTinChung || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChung",
                    data: data,
                });
            },
        },
        maxDate() {
            return this.$store.state.DVVTLenh.maxDate;
        },
    },
    watch: {},
    methods: {
        QuayLai() {
            this.KyLenhTuyChon_ThongTinChung.HanBaoHiemXe = new Date(
                this.tmpThongTinChung.HanBaoHiemXe,
            );
            this.KyLenhTuyChon_ThongTinChung.HanDangKiemXe = new Date(
                this.tmpThongTinChung.HanDangKiemXe,
            );
            this.KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen = new Date(
                this.tmpThongTinChung.HanPhuHieuTuyen,
            );
            return this.$router.go(-1);
        },
        async CapNhatThongTinGiayToXe() {
            let validate = this.$refs.formValidation.instance.validate();
            if (validate.isValid) {
                try {
                    let thongTinChung = this.KyLenhTuyChon_ThongTinChung;
                    const thongTinGiayToXe = {
                        iD_DnvtTuyen: thongTinChung.ID_DnvtTuyen,
                        iD_DnvtXe: thongTinChung.ID_DnvtXe,
                        hanBaoHiem: thongTinChung.HanBaoHiemXe,
                        hanDangKiem: thongTinChung.HanDangKiemXe,
                        hanBienNhanTheChap: thongTinChung.HanBienNhanTheChap,
                        hanPhuHieu: thongTinChung.HanPhuHieuTuyen,
                    };
                    let result = await this.$store.dispatch(
                        "ThongTin/CapNhatThongTinGiayToXe",
                        thongTinGiayToXe,
                    );
                    if (result.status == true) {
                        this.$Helper.ThongBaoToast(
                            "success",
                            "Cập nhật thông tin giấy tờ xe thành công!",
                        );
                        return this.$router.go(-1);
                    } else {
                        this.$Helper.ThongBaoPopup({
                            message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                            message: `${result.message}`,
                        });
                        return false;
                    }
                } catch (error) {
                    console.log("🚀 ~ error", error);
                    this.$Helper.ThongBaoPopup({
                        message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                        message: "Lỗi hệ thống",
                    });
                    return false;
                }
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            this.tmpThongTinChung.HanBaoHiemXe = new Date(
                this.KyLenhTuyChon_ThongTinChung.HanBaoHiemXe,
            );
            this.tmpThongTinChung.HanDangKiemXe = new Date(
                this.KyLenhTuyChon_ThongTinChung.HanDangKiemXe,
            );
            this.tmpThongTinChung.HanPhuHieuTuyen = new Date(
                this.KyLenhTuyChon_ThongTinChung.HanPhuHieuTuyen,
            );
        });
    },
    mounted() {},
};
</script>
<style scoped>
</style>
